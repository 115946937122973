/* NavBar.css */

/* Default styles */
nav {
  align-items: center;
  background-color: initial;
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 0 0 0 10%;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}

.scrolled {
  backdrop-filter: blur(5px);
}

.menu {
  display: flex;
  margin-right: 20%;
  list-style: none;
  padding: 0;
}

.menu > li {
  padding: 0 0.75rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.menu > li a {
  color: white;
  text-decoration: none;
  cursor: pointer;
  display: flex; /* Tetap flex untuk konsistensi */
  align-items: center;
}

.menu > li a i {
  display: none; /* Sembunyikan ikon secara default */
}

.menu > li a span {
  display: inline; /* Teks selalu terlihat */
}

.tophead > h1 {
  display: flex;
}

.name-logo {
  font-family: 'Space Mono', monospace;
  font-size: 1.3em;
  color: white;
  text-decoration: none;
}

.blink {
  height: 2rem;
  width: 15px;
  animation: blink 0.5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-bottom: 3px solid white;
  display: block;
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

.menu-btn {
  display: none;
}

.scroll-to-top {
  position: fixed;
  bottom: 3%;
  right: 3%;
  display: none;
}

/* Media Query untuk Mobile dan Tablet */
@media only screen and (max-width: 768px) {
  nav {
    padding: 0;
    top: auto;
    bottom: 0;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: fixed;
    background: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(10px);
    width: 100%;
    height: 60px;
    z-index: 999;
  }

  .scrolled {
    background: rgba(0, 0, 0, 0.9);
  }

  .tophead {
    display: none; /* Sembunyikan header di mobile */
  }

  .menu {
    margin: 0 !important;
    padding: 0;
    display: flex;
    flex-direction: row; /* Menu horizontal di mobile */
    width: 100%;
    justify-content: space-around;
    align-items: center;
    transition: none;
  }

  .menu.open {
    display: flex; /* Selalu terlihat di mobile */
  }

  .menu > li {
    font-size: 1rem;
    padding: 0;
    flex: 1;
    text-align: center;
  }

  .menu > li a {
    padding: 0.5rem 0; /* Kurangi padding agar muat ikon */
    display: flex;
    flex-direction: column; /* Ikon di atas teks */
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .menu > li a i {
    display: block; /* Tampilkan ikon di mobile */
    font-size: 1.2rem; /* Ukuran ikon */
    margin-bottom: 0.3rem; /* Jarak antara ikon dan teks */
  }

  .menu > li a span {
    font-size: 0.9rem; /* Ukuran teks sedikit lebih kecil */
  }

  .menu-btn {
    display: none; /* Tidak perlu tombol menu di bottom navbar */
  }

  .nav-open {
    height: 60px; /* Tinggi tetap untuk bottom navbar */
  }

  .scroll-to-top {
    bottom: 70px; /* Memberi ruang untuk bottom navbar */
    right: 20px;
  }

  .scroll-true {
    z-index: 99;
    background-color: #fff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    display: flex;
  }
}